import styled from 'styled-components';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Header>
        <h1>개인정보 처리방침</h1>
      </Header>
      
      <Content>
        <Section>
          <h2>1. 개인정보 수집 및 이용 목적</h2>
          <p>WithRun은 다음과 같은 목적으로 개인정보를 수집하고 있습니다:</p>
          <ul>
            <li>서비스 제공 및 계정 관리</li>
            <li>훈련 데이터 분석 및 공유</li>
            <li>서비스 개선 및 맞춤화</li>
          </ul>
        </Section>

        <Section>
          <h2>2. 수집하는 개인정보 항목</h2>
          <p>서비스 이용 과정에서 다음과 같은 정보들이 수집될 수 있습니다:</p>
          <ul>
            <li>계정 정보 (이메일, 이름)</li>
            <li>훈련 데이터 (거리, 시간, GPS 기록)</li>
            <li>서비스 이용 기록</li>
          </ul>
        </Section>

        <Section>
          <h2>3. 개인정보의 보유 및 이용기간</h2>
          <p>회원 탈퇴 시 또는 개인정보 삭제 요청 시까지 보관됩니다.</p>
        </Section>
      </Content>

      <Footer>
        <BackButton onClick={() => window.history.back()}>
          메인으로 돌아가기
        </BackButton>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  text-align: center;
  padding: 40px 0;
`;

const Content = styled.main`
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
`;

const Section = styled.section`
  margin-bottom: 40px;
  
  h2 {
    margin-bottom: 20px;
  }
  
  ul {
    padding-left: 20px;
  }
`;

const Footer = styled.footer`
  text-align: center;
  padding: 40px 0;
`;

const BackButton = styled.button`
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export default PrivacyPolicy; 