import styled from 'styled-components';
import { FaUsers, FaChartLine, FaBook } from 'react-icons/fa';
import heroBackground from '../assets/images/hero-background.jpg';

const LandingPage = () => {
  return (
    <Container>
      <HeroSection>
        <HeroContent>
          <h1>함께 달리고, 훈련하며, 성장하세요</h1>
          <p>러닝크루와 함께하는 새로운 러닝 경험</p>
          <CTAButton>시작하기</CTAButton>
        </HeroContent>
      </HeroSection>

      <FeaturesSection>
        <h2>주요 기능</h2>
        <FeatureGrid>
          <FeatureCard>
            <IconWrapper>
              <FaUsers />
            </IconWrapper>
            <h3>크루 생성 및 가입</h3>
            <p>나만의 러닝크루를 만들거나 기존 크루에 가입하세요</p>
          </FeatureCard>
          <FeatureCard>
            <IconWrapper>
              <FaChartLine />
            </IconWrapper>
            <h3>훈련 데이터 공유</h3>
            <p>크루원들과 훈련 데이터를 공유하고 비교해보세요</p>
          </FeatureCard>
          <FeatureCard>
            <IconWrapper>
              <FaBook />
            </IconWrapper>
            <h3>훈련일지</h3>
            <p>일지를 작성하고 크루원들과 소통하세요</p>
          </FeatureCard>
        </FeatureGrid>
      </FeaturesSection>

      <Footer>
        <FooterLinks>
          <a href="/privacy-policy">개인정보 처리방침</a>
          <a href="mailto:support@withrun.io">고객지원</a>
        </FooterLinks>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${heroBackground});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-color: #000;
`;

const HeroSection = styled.section`
  text-align: center;
  padding: 120px 20px;
  color: white;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  
  h1 {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
`;

const CTAButton = styled.button`
  padding: 15px 40px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #0056b3;
    transform: translateY(-2px);
  }
`;

const FeaturesSection = styled.section`
  padding: 80px 20px;
  text-align: center;
  background: transparent;
  max-width: 1200px;
  margin: 0 auto;
  
  h2 {
    margin-bottom: 3rem;
    font-size: 2.5rem;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-top: 40px;
`;

const IconWrapper = styled.div`
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 1.5rem;
  
  svg {
    transition: transform 0.3s ease;
  }
`;

const FeatureCard = styled.div`
  padding: 40px 30px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
    background: rgba(255, 255, 255, 0.95);
    
    ${IconWrapper} svg {
      transform: scale(1.1);
    }
  }
  
  h3 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  p {
    color: #666;
    line-height: 1.6;
  }
`;

const Footer = styled.footer`
  padding: 40px 20px;
  background: transparent;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const FooterLinks = styled.div`
  a {
    margin: 0 15px;
    color: white;
    text-decoration: none;
    transition: color 0.2s;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    
    &:hover {
      color: #007bff;
      text-decoration: underline;
    }
  }
`;

export default LandingPage; 